<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="优惠券名稱" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入优惠券名稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="店铺名稱" prop="storeId">
                <a-input v-model="queryParam.storeName" placeholder="请输入店铺名稱" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查詢</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <coupen-user
        ref="coupenUser"
        @ok="getList"
      />

      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="dueTime" slot-scope="text, record">
          {{ parseTime(record.dueTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.coupenUser.show(record)" v-if="record.applicationStatus != 0 && record.applicationStatus != 2">
             申领记录
          </a>
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-if="roleId == 2 && record.applicationStatus == 0">
             审核
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageCoupon,listCoupon, delCoupon } from '@/api/platform/coupon'
import CreateForm from './modules/CreateForm'
import CoupenUser from './coupenUser'
import { tableMixin } from '@/store/table-mixin'
import request from "@/utils/request";

export default {
  name: 'Coupon',
  components: {
    CreateForm,
    CoupenUser
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      roleId: null,
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查詢参数
      queryParam: {
        name: null,
        minMoney: null,
        derateMoney: null,
        storeId: null,
        status: null,
        dueTime: null,
        applicationStatus: null,
        refusalCause: null,
        maxNum: null,
        surplusNum: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '优惠券名稱',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最低消费金額',
          dataIndex: 'minMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '减免金額',
          dataIndex: 'derateMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '店铺名稱',
          dataIndex: 'storeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '狀態',
          dataIndex: 'status',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "正常"
            }
            if (value == 1) {
              return "停用"
            }
          }
        },
        {
          title: '到期時間',
          dataIndex: 'dueTime',
          scopedSlots: { customRender: 'dueTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '总数量',
          dataIndex: 'maxNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '剩余数量',
          dataIndex: 'surplusNum',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '申请狀態',
          dataIndex: 'applicationStatus',
          ellipsis: true,
          align: 'center',
          customRender: function (value, record) {
            if (value == 0) {
              return "待审核"
            }
            if (value == 1) {
              return "已同意"
            }
            if (value == 2) {
              return "已拒绝"
            }
            if (value == 3) {
              return "领取完"
            }
            if (value == 4) {
              return "已过期"
            }
          }
        },
        {
          title: '拒绝原因',
          dataIndex: 'refusalCause',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '插入時間',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
    this.getRole()
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查詢优惠券-信息列表 */
    getList () {
      this.loading = true
     pageCoupon(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    getRole(){
      let that = this;
      request({
        url: '/system/user/getRole',
        method: 'post'
      }).then(response => {
        that.roleId = response.data;
      })
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        minMoney: undefined,
        derateMoney: undefined,
        storeId: undefined,
        status: undefined,
        dueTime: undefined,
        applicationStatus: undefined,
        refusalCause: undefined,
        maxNum: undefined,
        surplusNum: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 刪除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      if(row.id){
        this.ids.push(row.id)
      }
      this.$confirm({
        title: '确认刪除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          let data = []
          that.ids.map(id => data.push({"id":id}))
          return delCoupon(data)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '刪除成功',
                3
              )
          })
        },
        onCancel () {
          //取消清空刪除ids列表
          that.ids = []
        }
      })
    },
    /** 導出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认導出?',
        content: '此操作将導出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('platform/coupon/export', {
            ...that.queryParam
          }, `优惠券-信息_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
